import React, { Fragment } from "react";
import styled from "styled-components";
import MatchExpandedTeam from "./MatchExpandedTeam";
import MatchExpandedRowHeader from "./MatchExpandedRowHeader";
import Card from "../../Card";

const StyledMatchExpanded = styled.div`
    /* padding: 7px; */
    /* background: #292f35; */
    /* padding: 0 7px; */
`;

function MatchExpanded({ matchData, championId }) {
    /*
    !Move fetching up one level, because atm it fetches every time you expand. 
    !Also, later on just make it fetch all of first 10 matches on load.
    */
    // const [fetchedMatchData, setFetchedMatchData] = useState({});

    // useEffect(() => {
    //     const fetchData = async () => {
    //         setFetchedMatchData(await fetchMatchData(region, gameId));
    //     };
    //     fetchData();
    //     // console.log("hi", fetchMatchData[0]);
    // }, [setFetchedMatchData]);

    // const gridTemplateColumns = "minmax(200px, 3fr) 2fr 2fr 2fr 1fr 1fr 4fr";
    const gridTemplateColumns = "3fr 2fr 2fr 2fr 1fr 1fr 4fr";

    const win = {
        true: "rgba(0, 0, 255, 0.2)",
        false: "rgba(255, 0, 0, 0.2)",
    };

    return (
        <StyledMatchExpanded>
            {console.log("matchData", matchData)}
            {matchData["team1"] ? (
                <Fragment>
                    <Card padding="0 7px">
                        <MatchExpandedRowHeader
                            color={win[matchData["team1"][0].stats.win]}
                            gridTemplateColumns={gridTemplateColumns}
                            win={matchData["team1"][0].stats.win}
                            team="Blue"
                            items={[
                                "Tier",
                                "KDA",
                                "Damage",
                                "Wards",
                                "CS",
                                "Items",
                            ]}
                        />
                        <MatchExpandedTeam
                            color={win[matchData["team1"][0].stats.win]}
                            gridTemplateColumns={gridTemplateColumns}
                            matchData={matchData["team1"]}
                        />
                        <MatchExpandedRowHeader
                            color={win[matchData["team2"][0].stats.win]}
                            gridTemplateColumns={gridTemplateColumns}
                            win={matchData["team2"][0].stats.win}
                            team="Red"
                            items={[
                                "Tier",
                                "KDA",
                                "Damage",
                                "Wards",
                                "CS",
                                "Items",
                            ]}
                        />
                        <MatchExpandedTeam
                            color={win[matchData["team2"][0].stats.win]}
                            gridTemplateColumns={gridTemplateColumns}
                            matchData={matchData["team2"]}
                        />
                    </Card>
                </Fragment>
            ) : (
                <p>Loading...</p>
            )}
        </StyledMatchExpanded>
    );
}

export default MatchExpanded;
