import axios from "axios";
import api_key from "./api_key";
import { keys as championKeys } from "../assets/json/championFull.json";
// import { summoner as summonerSpells } from "../assets/json/summoner.json";

// const header = {
//     "User-Agent":
//         "Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:79.0) Gecko/20100101 Firefox/79.0",
//     "Accept-Language": "en-US,en;q=0.5",
//     "Accept-Charset": "application/x-www-form-urlencoded; charset=UTF-8",
//     Origin: "https://developer.riotgames.com",
//     "X-Riot-Token": api_key,
// };

const regionRoutes = {
    na1: "americas",
    br1: "americas",
    lan: "americas",
    las: "americas",
    la1: "americas",
    la2: "americas",
    kr: "asia",
    jp1: "asia",
    eun1: "europe",
    euw1: "europe",
    tr1: "europe",
    ru: "europe",
    oc1: "sea",
};

export const fetchSummonerData = async (region, summonerName) => {
    try {
        const summonerDataUrl = `https://${region}.api.riotgames.com/lol/summoner/v4/summoners/by-name/${summonerName}?api_key=${api_key}`;
        const {
            data: { id, accountId, name, profileIconId, summonerLevel, puuid },
        } = await axios.get(summonerDataUrl);
        const data = await axios.get(summonerDataUrl);
        let modifiedData = {
            summonerId: id,
            accountId,
            name,
            profileIconId,
            summonerLevel,
        };
        // console.log("summoner_data:", data);
        return modifiedData;
    } catch (error) {
        return {};
    }
};

export const fetchMasteryData = async (region, summonerId) => {
    try {
        //Removed because changed id fetching location from Mastery.js to Summoner.js
        // const { id } = await fetchSummonerData(region, summonerName);
        const masteryDataUrl = `https://${region}.api.riotgames.com/lol/champion-mastery/v4/champion-masteries/by-summoner/${summonerId}?api_key=${api_key}`;
        const { data } = await axios.get(masteryDataUrl);
        const modifiedData = data
            .slice(0, 50)
            .map(
                ({
                    championId,
                    championLevel,
                    championPoints,
                    tokensEarned,
                    chestGranted,
                }) => ({
                    championId: championKeys[championId],
                    championLevel: championLevel,
                    championPoints: championPoints,
                    tokensEarned: tokensEarned,
                    chestGranted: chestGranted,
                })
            );

        // console.log("masteries_data:", data);

        return modifiedData;
    } catch (error) {}
};

export const fetchMatchesData = async (region, accountId) => {
    const beginIndex = 0;
    const endIndex = 10;

    const testPuuid =
        "8BUOF4kXtndOHgEPLytk-iLMQ5_hXkvrAjveMqOm-Yl4inx0geIX2zQIg7NwtV3XT7rRCuUwT_5cbA";

    try {
        const historyDataUrl = `https://${regionRoutes[region]}.api.riotgames.com/lol/match/v5/matches/by-puuid/8BUOF4kXtndOHgEPLytk-iLMQ5_hXkvrAjveMqOm-Yl4inx0geIX2zQIg7NwtV3XT7rRCuUwT_5cbA/ids?start=${beginIndex}&count=${endIndex}&api_key=${api_key}`;
        // const historyDataUrl = `https://${region}.api.riotgames.com/lol/match/v5/matchlists/by-account/${accountId}?endIndex=${endIndex}&beginIndex=${beginIndex}&api_key=${api_key}`;
        const { data } = await axios.get(historyDataUrl);

        // const modifiedMatchesData = matches.map(({ champion, matchId }) => ({
        //     /**
        //      * !Eventually make a function that does this automatically
        //      * */
        //     championId: championKeys[champion],
        //     matchId,
        // }));

        // // return modifiedMatchesData;
        // return modifiedMatchesData;
        return data;
    } catch (error) {}
};

export const fetchMatchData = async (region, matchId) => {
    try {
        const matchDataUrl = `https://${regionRoutes[region]}.api.riotgames.com/lol/match/v5/matches/${matchId}?api_key=${api_key}`;
        const {
            data: {
                info: {
                    gameCreation,
                    gameDuration,
                    gameMode,
                    participants,
                    participantIdentities,
                },
            },
        } = await axios.get(matchDataUrl);

        const {
            data: { info },
        } = await axios.get(matchDataUrl);
        // console.log("YES", info);

        const modifiedParticipants = participants.map(
            (
                {
                    participantId,
                    summonerName,
                    teamId,
                    championId,
                    summoner1Id,
                    summoner2Id,
                    win,
                    item0,
                    item1,
                    item2,
                    item3,
                    item4,
                    item5,
                    item6,
                    kills,
                    deaths,
                    assists,
                    totalDamageDealtToChampions,
                    wardsPlaced,
                    totalMinionsKilled,
                },
                index
            ) => ({
                summonerName,
                teamId,
                championId: championKeys[championId],
                summoner1Id,
                summoner2Id,
                stats: {
                    win,
                    item0,
                    item1,
                    item2,
                    item3,
                    item4,
                    item5,
                    item6,
                    kills,
                    deaths,
                    assists,
                    totalDamageDealtToChampions,
                    wardsPlaced,
                    totalMinionsKilled,
                },
            })
        );

        const modifiedData = {
            gameCreation,
            gameDuration,
            gameMode,
            team1: modifiedParticipants.slice(0, 5),
            team2: modifiedParticipants.slice(5, 10),
        };

        // console.log("matches_data", matchId, modifiedData);
        return modifiedData;
    } catch (error) {}
};

export const fetchRankData = async (region, summonerId) => {
    const rankDataUrl = `https://${region}.api.riotgames.com/lol/league/v4/entries/by-summoner/${summonerId}?api_key=${api_key}`;

    const { data } = await axios.get(rankDataUrl);

    const modifiedData = {};

    for (let i = 0; i < data.length; i++) {
        let queueTypeData = data[i].queueType;
        if (
            queueTypeData === "RANKED_SOLO_5x5" ||
            queueTypeData === "RANKED_FLEX_SR"
        ) {
            modifiedData[data[i].queueType] = data[i];
        }
    }
    // console.log("RANK", modifiedData);

    return modifiedData;
};
