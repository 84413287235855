import React from "react";
import styled from "styled-components";
import MasteryTopThree from "./MasteryTopThree";
import MasteryExpanded from "./MasteryExpanded";

const StyledMastery = styled.div`
    /* flex-direction: column; */
    /* color: #fff; */
    /* padding: 70px; */
    /* background: #2f363d; */
    margin: 0 auto;
    /* width: 1000px; */
    background: #292f35;
    .mastery-container {
        .top-three {
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }
`;

function Mastery({ masteryData }) {
    return (
        <StyledMastery>
            {masteryData[0] ? (
                <div className="mastery-container">
                    <MasteryTopThree masteryData={masteryData.slice(0, 3)} />

                    <MasteryExpanded masteryData={masteryData} />
                </div>
            ) : (
                <p>Does Not Exist</p>
            )}
        </StyledMastery>
    );
}

export default Mastery;
