import React from "react";
import styled from "styled-components";

const StyledNotFound = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    .not-found-text {
        color: white;
    }
`;

function NotFound({ title, className }) {
    return (
        <StyledNotFound className={className}>
            <p className="not-found-text">404 Page Not Found</p>
        </StyledNotFound>
    );
}

export default NotFound;
