import React from "react";
import styled from "styled-components";

const StyledMasteryExpandedRowHeader = styled.ul`
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 2fr 2fr 10fr;
    align-items: center;
    color: #fff;
    list-style-type: none;
    padding-left: 8px;
    .bar-item {
        font-size: 1.4rem;
        font-weight: 800;
    }
`;

function MasteryExpandedRowHeader({ items }) {
    return (
        <StyledMasteryExpandedRowHeader>
            {items.map((item, index) => (
                <li key={index} className="bar-item">
                    {item}
                </li>
            ))}
        </StyledMasteryExpandedRowHeader>
    );
}

export default MasteryExpandedRowHeader;
