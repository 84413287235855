import React from "react";
import styled from "styled-components";

const StyledRegionOption = styled.p`
    font-size: 1rem;
`;

const RegionOption = ({ value, name, children /* handleClick */ }) => {
    return (
        <StyledRegionOption
            value={value}
            name={name}
        >
            {children}
        </StyledRegionOption>
    );
};

export default RegionOption;
