import React from "react";
import styled from "styled-components";

const StyledCard = styled.div`
    /* margin: 7px 0;
    padding: 10px 0; */
    /* background: #333c43; */
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 1);
`;

function Card({ className, color, margin, padding, onClick, children }) {
    return (
        <StyledCard
            className={className}
            style={{ margin, padding, backgroundColor: color }}
            onClick={onClick}
        >
            {children}
        </StyledCard>
    );
}

export default Card;
