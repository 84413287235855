import React from "react";
import styled from "styled-components";

const StyledLabel = styled.div`
    width: 100%;
    background: #333c43;
    border: 1px solid gray;

    .label-title {
        margin: 10px 20px;
        font-weight: 500;
        font-size: 1.3rem;
        text-align: left;
    }
`;

function Label({ title, className }) {
    return (
        <StyledLabel className={className}>
            <h3 className="label-title">{title}</h3>
        </StyledLabel>
    );
}

export default Label;
