import React from "react";
import styled from "styled-components";
import MasteryExpandedContent from "./MasteryExpandedContent";
import MasteryExpandedRowHeader from "./MasteryExpandedRowHeader";

const StyledMasteryExpanded = styled.div``;

function MasteryExpanded({ masteryData }) {
    return (
        <StyledMasteryExpanded>
            <MasteryExpandedRowHeader
                items={[
                    "Rank",
                    "Champion",
                    "Mastery",
                    "Points",
                    "Tokens",
                    "Chests",
                ]}
            />
            {masteryData.map((champion, index) => (
                <MasteryExpandedContent
                    key={index}
                    rank={index + 1}
                    champion={champion}
                />
            ))}
        </StyledMasteryExpanded>
    );
}

export default MasteryExpanded;
