import React, { Component } from "react";
import styled from "styled-components";
// import { fetchMasteryData, fetchSummonerData } from "../api/index";
// import TopThree from "../components/Mastery/TopThree";
// import MasteryApp from "../components/Mastery/MasteryApp/MasteryApp";

const StyledHome = styled.div``;

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {};

    }
    render() {
        return <StyledHome></StyledHome>;
    }
}

export default Home;
