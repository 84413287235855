import React from "react";
import styled from "styled-components";
import ImageCropper from "../../ImageCropper";
import ImageFetcher from "../../../util/ImageFetcher";
import { Link } from "react-router-dom";

const StyledMatchExpandedTeam = styled.div`
    .summoner-row {
        display: grid;
        align-items: center;
        border-radius: 5px;
        margin: 3px 0;
        padding: 5px;
        .champion-details {
            display: grid;
            grid-template-columns: 1fr 1fr minmax(100px, 4fr);
            grid-gap: 15px;
            align-items: center;
            .summoner-spells-and-mastery {
                display: flex;
                .summoner-spell-icon {
                    width: 20px;
                }
                .summoner-mastery-icon {
                    width: 20px;
                }
            }
            .summoner-name {
                text-align: left;
                font-size: 1.4rem;
                overflow: hidden;
                text-overflow: ellipsis;
                text-decoration: none;
                color: #fff;
                &:hover {
                    font-weight: 600;
                }
            }
        }
        .items-list {
            display: flex;
            justify-content: center;
            .item {
                margin-right: 6px;
                width: 35px;
                height: 35px;
            }
            .item.empty {
                background-color: rgba(255, 255, 255, 0.1);
            }
            .item.undefined {
                background-color: rgba(0, 0, 0, 0.2);
                border: 1px solid black;
            }
            @media only screen and (max-width: 1205px) {
                .item {
                    width: 30px;
                    height: 30px;
                }
            }
            @media only screen and (max-width: 1060px) {
                .item {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
`;

function MatchExpandedTeam({
    matchData,
    color,
    gridTemplateColumns,
    className,
}) {
    const handleClick = (summonerName) => {
        window.location.href = `/summoner/na1/${summonerName}`;
    };
    return (
        <StyledMatchExpandedTeam className={className}>
            {/**matchData is participants.slice(0, 5) */}
            {matchData.map(
                (
                    {
                        summonerName,
                        teamId,
                        championId,
                        spell1Id,
                        spell2Id,
                        stats: {
                            win,
                            item0,
                            item1,
                            item2,
                            item3,
                            item4,
                            item5,
                            item6,
                            kills,
                            deaths,
                            assists,
                            totalDamageDealtToChampions,
                            wardsPlaced,
                            totalMinionsKilled,
                        },
                    },
                    index
                ) => (
                    <div
                        className="summoner-row"
                        style={{ backgroundColor: color, gridTemplateColumns }}
                        key={index}
                    >
                        <div className="champion-details">
                            <ImageCropper
                                championId={championId}
                                width="45px"
                                margin="3px"
                                border="1px solid #fff"
                                circle
                            />
                            <div className="summoner-spells-and-mastery">
                                <div className="summoner-spells">
                                    <ImageFetcher
                                        type="champion"
                                        imageId={championId}
                                        className="summoner-spell-icon"
                                        alt="summoner spell"
                                    />
                                    <ImageFetcher
                                        type="champion"
                                        imageId={championId}
                                        className="summoner-spell-icon"
                                        alt="summoner spell"
                                    />
                                </div>
                                <div className="summoner-mastery">
                                    <ImageFetcher
                                        type="champion"
                                        imageId={championId}
                                        className="summoner-mastery-icon"
                                        alt="summoner mastery"
                                    />
                                    <ImageFetcher
                                        type="champion"
                                        imageId={championId}
                                        className="summoner-mastery-icon"
                                        alt="summoner mastery"
                                    />
                                </div>
                            </div>
                            {/**!Once redux is applied, add template literals for region */}
                            <Link
                                onClick={() => handleClick(summonerName)}
                                className="summoner-name"
                                to={`/summoner/na1/${summonerName}/history`}
                            >
                                {summonerName}
                            </Link>
                        </div>
                        <p className="rank-tier">Unranked</p>
                        <p className="KDA">
                            {kills}/{deaths}/{assists}
                        </p>
                        <p className="total-damage">
                            {totalDamageDealtToChampions}
                        </p>
                        <p className="wards-placed">{wardsPlaced}</p>
                        <p className="total-minions">{totalMinionsKilled}</p>
                        <div className="items-list">
                            <ImageFetcher
                                type="item"
                                imageId={item0}
                                className="item empty"
                                alt="item"
                            />
                            <ImageFetcher
                                type="item"
                                imageId={item1}
                                className="item"
                                alt="item"
                            />
                            <ImageFetcher
                                type="item"
                                imageId={item2}
                                className="item"
                                alt="item"
                            />
                            <ImageFetcher
                                type="item"
                                imageId={item3}
                                className="item"
                                alt="item"
                            />
                            <ImageFetcher
                                type="item"
                                imageId={item4}
                                className="item"
                                alt="item"
                            />
                            <ImageFetcher
                                type="item"
                                imageId={item5}
                                className="item"
                                alt="item"
                            />
                            <ImageFetcher
                                type="item"
                                imageId={item6}
                                className="item"
                                alt="item"
                            />
                        </div>
                    </div>
                )
            )}
            {/* {console.log("card", matchData)} */}
        </StyledMatchExpandedTeam>
    );
}

export default MatchExpandedTeam;
