import React, { useState } from "react";
import styled from "styled-components";
import MatchContent from "./MatchContent";
import { fetchMatchData } from "../../../api/index";
import Label from "../../../util/Label";

const StyledMatch = styled.div`
    /* background: #292f35; */
`;

/**
 * *We don't fetch matchlist data here because when we load :summoner/history directly, the accountId:
 * *1) From Summoner.js (during await fetchSummonerData), Match.js's useEffect won't have the accountId to fetchMatchList.
 * *2) We have to call a separate api fetch for it.
 * *2) We can't have accountId passed through url params because it's a protected data.
 */
function Match({ region, matchesData, className }) {
    //temporary
    const [expandedItems, setExpandedItems] = useState({});
    const [fetchedMatchData, setFetchedMatchData] = useState({});

    const handleExpand = async (index, matchId) => {
        setExpandedItems({
            ...expandedItems,
            [index]: !expandedItems[index],
        });
        if (!fetchedMatchData[index]) {
            setFetchedMatchData({
                ...fetchedMatchData,
                [index]: await fetchMatchData(region, matchId),
            });
        }
    };

    return (
        <StyledMatch className={className}>
            <Label title="Match History" />
            {matchesData[0] ? (
                matchesData.map(
                    (
                        matchId,
                        // championId
                        index
                    ) => (
                        <MatchContent
                            key={index}
                            // championId={championId}
                            // matchId={matchId}
                            onClick={() => handleExpand(index, matchId)}
                            isExpanded={
                                expandedItems[index] && fetchedMatchData[index]
                            }
                            matchData={fetchedMatchData[index]}
                        />
                    )
                )
            ) : (
                <p>Loading...</p>
            )}
        </StyledMatch>
    );
}

export default Match;
