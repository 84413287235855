import React from "react";
import styled from "styled-components";

import ImageFetcher from "../util/ImageFetcher";

const StyledImageCropper = styled.div`
    border-radius: 50%;
    overflow: hidden;
    z-index: 1;
`;

const ImageCropper = ({
    championId,
    width,
    height,
    margin,
    circle,
    border,
}) => {
    const cropStyle = {
        width,
        height: height ? height : width,
        margin: `${margin ? margin : 0} auto`,
        borderRadius: circle ? "50%" : "0%",
        border,
    };
    return (
        <StyledImageCropper style={cropStyle}>
            <ImageFetcher
                type="champion"
                imageId={championId}
                className="champion-image"
                alt="champion"
            />
        </StyledImageCropper>
    );
};

export default ImageCropper;
