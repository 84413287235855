import React from "react";
import styled from "styled-components";
import Match from "../match/Match";
import Rank from "../rank/Rank";

const StyledOverview = styled.div`
    display: grid;
    grid-template-columns: 2fr 5fr;
    grid-gap: 12px;
    /* grid-template-areas:
        "rank match"
        "rankTotal match";
    .rank-container {
        grid-area: rank;
    }
    .rank-total-container {
        grid-area: rankTotal;
    }
    .match-container {
        grid-area: match;
    } */
`;

function Overview({ region, rankData, matchesData, isLoaded }) {
    return (
        <StyledOverview>
            <Rank
                className="rank-container"
                rankData={rankData}
                isLoaded={isLoaded}
            />

            <Match
                className="match-container"
                region={region}
                matchesData={matchesData}
            />
        </StyledOverview>
    );
}

export default Overview;
