import React from "react";
import styled from "styled-components";
import Card from "../../Card";
import ImageCropper from "../../ImageCropper";
import MatchExpanded from "./MatchExpanded";

const StyledMatchContent = styled.div`
    .champion-details {
        .champion-image {
            /**For now, manually center in cropped circle (Image width - crop width)/2 */
            transform: translate(-5px, -5px);
            z-index: 1;
            width: 55px;
        }
        .champion-name {
            font-size: 1rem;
        }
    }
`;

function MatchContent({ matchData, championId, onClick, isExpanded }) {
    return (
        <StyledMatchContent>
            <Card
                onClick={onClick}
                color="#333c43"
                padding="7px"
                margin="7px 0"
            >
                <div className="champion-details">
                    <ImageCropper
                        championId={championId}
                        width="45px"
                        margin="3px"
                        border="1px solid #fff"
                        circle
                    />
                    <h4 className="champion-name">{championId}</h4>
                </div>
            </Card>
            {isExpanded && (
                <MatchExpanded
                    // championId={championId}
                    matchData={matchData}
                />
            )}
        </StyledMatchContent>
    );
}

export default MatchContent;
