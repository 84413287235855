import React from "react";
import styled from "styled-components";
import ChestIcon from "../../../assets/images/loot/Hextech-Chest.png";
import ImageCropper from "../../ImageCropper";

import Card from "../../Card";

const StyledMasteryExpandedContent = styled.div`
    .mastery-container {
        display: grid;
        grid-template-columns: 1fr 2fr 2fr 2fr 2fr 2fr 10fr;
        align-items: center;
        color: #fff;
        p {
            font-weight: 500;
            font-size: 1.4rem;
        }
        .mastery-rank {
        }
        .champion-details {
            .champion-image {
                /**For now, manually center in cropped circle (Image width - crop width)/2 */
                transform: translate(-5px, -5px);
                z-index: 1;
                width: 55px;
            }
            .champion-name {
                font-size: 1.2rem;
            }
        }
        .mastery-details {
            .mastery-image {
                width: 50px;
                z-index: 2;
            }

            .mastery-level {
                font-weight: 600;
            }
        }
        .mastery-points {
            font-weight: 600;
        }
        .token-progress {
        }
        .chest-image {
            width: 60px;
        }
    }
`;

function MasteryExpandedContent({
    rank,
    champion: {
        championId,
        championLevel,
        championPoints,
        tokensEarned,
        chestGranted,
    },
}) {
    return (
        <StyledMasteryExpandedContent>
            <Card
                className="mastery-container"
                color="#333c43"
                margin="7px 0"
                padding="10px"
                key={rank}
            >
                <p className="mastery-rank">{rank}</p>
                <div className="champion-details">
                    <ImageCropper
                        championId={championId}
                        width="45px"
                        margin="3px"
                        border="1px solid #fff"
                        circle
                    />
                    <p className="champion-name">{championId}</p>
                </div>
                <div className="mastery-details">
                    <img
                        className="mastery-image"
                        src={require(`../../../assets/images/champion-mastery-icons/mastery-${championLevel}.png`)}
                        alt="mastery"
                    />
                    <p className="mastery-level">{championLevel}</p>
                </div>
                <p className="mastery-points">{championPoints}</p>
                <p className="token-progress">
                    {championLevel === 7
                        ? "Max"
                        : `${tokensEarned}/${championLevel === 6 ? 3 : 2}`}
                </p>
                <div>
                    {chestGranted ? (
                        <img
                            className="chest-image"
                            src={ChestIcon}
                            alt="hextech chest"
                        />
                    ) : (
                        <p>
                            Chest <br />
                            Available
                        </p>
                    )}
                </div>
            </Card>
        </StyledMasteryExpandedContent>
    );
}

export default MasteryExpandedContent;
