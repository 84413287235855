import React from "react";
import styled from "styled-components";
import ImageCropper from "../../ImageCropper";

const StyledMasteryTopThree = styled.div`
    display: flex;
    justify-content: center;
    .mastery1,
    .mastery2,
    .mastery3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        margin: 40px 12px 40px 12px;
    }
    .mastery1 {
        transform: translateY(-30px);
    }

    .champion-image {
        /**For now, manually center in cropped circle (Image width - crop width)/2 */
        transform: translate(-5px, -5px);
        width: 90px;
        z-index: 1;
    }

    .mastery-image {
        margin-top: -25px;
        width: 60px;
        z-index: 2;
    }
    .champion-name {
        font-size: 2rem;
        color: ${(props) => props.theme.color.primary};
        font-weight: 600;
    }
    .mastery-level {
        font-size: 1.4rem;
    }
    .mastery-points {
        font-size: 1.4rem;
        font-weight: 600;
    }
`;

function MasteryTopThree({ masteryData }) {
    return (
        <StyledMasteryTopThree>
            <div className="mastery2">
                <ImageCropper
                    championId={masteryData[1].championId}
                    width="80px"
                    border="2px solid #fff"
                    circle
                />
                <img
                    className="mastery-image"
                    src={require(`../../../assets/images/champion-mastery-icons/mastery-${masteryData[1].championLevel}.png`)}
                    alt="mastery"
                />
                <p className="mastery-level">
                    Mastery {masteryData[1].championLevel}
                </p>
                <p className="champion-name">{masteryData[1].championId}</p>
                <p className="mastery-points">
                    {masteryData[1].championPoints}
                </p>
            </div>
            <div className="mastery1">
                <ImageCropper
                    championId={masteryData[0].championId}
                    width="80px"
                    border="2px solid #fff"
                    circle
                />
                <img
                    className="mastery-image"
                    src={require(`../../../assets/images/champion-mastery-icons/mastery-${masteryData[0].championLevel}.png`)}
                    alt="mastery"
                />
                <p className="mastery-level">
                    Mastery {masteryData[0].championLevel}
                </p>
                <p className="champion-name">{masteryData[0].championId}</p>
                <p className="mastery-points">
                    {masteryData[0].championPoints}
                </p>
            </div>
            <div className="mastery3">
                <ImageCropper
                    championId={masteryData[2].championId}
                    width="80px"
                    border="2px solid #fff"
                    circle
                />
                <img
                    className="mastery-image"
                    src={require(`../../../assets/images/champion-mastery-icons/mastery-${masteryData[2].championLevel}.png`)}
                    alt="mastery"
                />
                <p className="mastery-level">
                    Mastery {masteryData[2].championLevel}
                </p>
                <p className="champion-name">{masteryData[2].championId}</p>
                <p className="mastery-points">
                    {masteryData[2].championPoints}
                </p>
            </div>
        </StyledMasteryTopThree>
    );
}

export default MasteryTopThree;
