import React from "react";
import styled from "styled-components";
import Label from "../../../util/Label";
import RankContent from "./RankContent";
import RankMostPlayed from "./RankMostPlayed";

const StyledRank = styled.div`
    .rank-content-container {
        margin: 7px 0;
    }
    .rank-total-content {
        height: 210px;
        margin: 7px 0;
    }
    .rank-total-label {
        margin-top: 15px;
    }
`;

function Rank({ region, rankData, className, isLoaded }) {

    return (
        <StyledRank className={className}>
            <Label title="Rank" className="rank-label" />
            {/**!Just change back to 1 RankContent, so it can calculate if rankData exists */}
            <RankContent
                rankData={rankData}
                className="rank-content-container"
                isLoaded={isLoaded}
            />
            <Label title="Total" className="rank-total-label" />
            <RankMostPlayed
                className="rank-total-content"
                rankData={rankData}
            />
        </StyledRank>
    );
}

export default Rank;
