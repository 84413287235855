import React, { Component } from "react";
import styled, { withTheme } from "styled-components";
import Mastery from "../components/summoner/champion-mastery/Mastery";
import dayjs from "dayjs";

import {
    fetchSummonerData,
    fetchRankData,
    fetchMatchesData,
    fetchMasteryData,
} from "../api";

import { NavLink } from "react-router-dom";
import Overview from "../components/summoner/overview/Overview";
import Button from "../components/layout/Button";

// import { fetchMasteryData, fetchSummonerData } from "../api/index";
// import TopThree from "../components/Mastery/TopThree";
// import MasteryApp from "../components/Mastery/MasteryApp/MasteryApp";

const StyledSummoner = styled.div`
    max-width: 1000px;
    margin: 10px auto;
    color: #fff;
    text-align: center;
    padding: 55px 0;
    .no-profile-text {
        margin: 40px;
    }
    .summoner-content-header {
        display: flex;
        align-items: center;
        text-align: left;
        .summoner-icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            margin: 30px;
            .summoner-icon-image {
                width: 110px;
                border-radius: 50%;
                border: 1px solid #fff;
                margin: 0 auto;
            }
            .summoner-level {
                font-size: 1.8rem;
                border: 2px solid #fff;
                padding: 0 3px;
                line-height: 20px;
                margin: -20px auto 0 auto;
                background: #000;
            }
        }
        .summoner-details {
            .summoner-name {
                font-size: 4rem;
                font-weight: 500;
                letter-spacing: 0px;
            }
            .summoner-update-button {
            }
            .summoner-last-updated {
                font-size: 1.2rem;
                margin: 5px;
                font-weight: 500;
            }
        }
    }

    nav {
        text-align: left;
        border-bottom: 1px solid gray;
        margin-bottom: 25px;
        .nav-link {
            color: #fff;
            /* font-size: 2rem; */
            font-size: 2.2rem;
            transition: all 0.1s ease-in-out;
            outline: none;
            text-decoration: none;
            /* padding: 0 7px; */
            padding: 0 15px;
            /* margin-right: 10px; */

            &:hover {
                color: ${(props) => props.theme.color.primary};
                /* border-bottom: solid #00cce7; */
            }
        }
    }
`;

// let isMounted;
class Summoner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            region: "",
            lastUpdate: "null",
            summonerData: {},
            matchesData: [],
            masteryData: [],
            rankData: {},
            currentTab: "",
            url: ``,
            isLoaded: false,
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.fetchData = this.fetchData.bind(this);
    }
    async componentDidMount() {
        //!If localhost:3000/, it searches summoner "Undefined"
        //!Memory leak issue if started from summoner page into history/mastery
        const region = this.props.match.params.region;
        const summonerName = this.props.match.params.summonerName;
        const timestamp = dayjs().format("MMM D YYYY h:mm A");

        this.setState({
            region: region,
            currentTab: this.props.match.params.stat || "overview",
            url: `/summoner/${this.props.match.params.region}/${this.props.match.params.summonerName}`,
        });
        const fetchedSummonerData = await fetchSummonerData(
            region,
            summonerName
        );

        if (fetchedSummonerData) {
            this.setState({
                region: region,
                summonerData: fetchedSummonerData,
                lastUpdate: null,
                isLoaded: true,
            });
            this.fetchData(this.props.match.params.stat);
        }
    }

    async componentDidUpdate(prevProps) {
        const region = this.props.match.params.region;
        const prevRegion = prevProps.match.params.region;
        const summonerName = this.props.match.params.summonerName;
        const prevSummonerName = prevProps.match.params.summonerName;
        const timestamp = dayjs().format("MMM D YYYY h:mm A");

        // Typical usage (don't forget to compare props):
        if (region !== prevRegion || summonerName !== prevSummonerName) {
            const fetchedSummonerData = await fetchSummonerData(
                region,
                summonerName
            );

            if (fetchedSummonerData) {
                this.setState({
                    region: region,
                    summonerData: fetchedSummonerData,
                    lastUpdate: null,
                    isLoaded: true,
                    currentTab: this.props.match.params.stat || "overview",
                    masteryData: [],
                    url: `/summoner/${this.props.match.params.region}/${this.props.match.params.summonerName}`,
                });
                this.fetchData(this.props.match.params.stat);
            }
            // console.log(this.state.summonerData);
        }
    }

    handleSelect = async (tab) => {
        this.setState({
            currentTab: tab,
        });
        this.fetchData(tab);
    };
    handleUpdate = () => {
        const timestamp = dayjs().format("MMM D YYYY h:mm A");

        this.setState({
            lastUpdate: timestamp,
        });
    };
    fetchData = async (tab) => {
        //Prevents calling api if there is already data
        if (tab === "mastery" && !this.state.masteryData[0]) {
            const fetchedMasteryData = await fetchMasteryData(
                this.state.region,
                this.state.summonerData.summonerId
            );
            this.setState({
                masteryData: fetchedMasteryData || [],
            });
            // console.log(this.state.masteryData);
        }
        if (tab === "overview") {
            if (!this.state.rankData.summonerId) {
                const fetchedRankData = await fetchRankData(
                    this.state.region,
                    this.state.summonerData.summonerId
                );
                this.setState({
                    rankData: fetchedRankData || {},
                });
            }
            if (!this.state.matchesData[0]) {
                const fetchedMatchesData = await fetchMatchesData(
                    this.state.region,
                    this.state.summonerData.accountId
                );
                this.setState({
                    matchesData: fetchedMatchesData || [],
                });
            }
        }
    };
    render() {
        const {
            region,
            lastUpdate,
            summonerData,
            rankData,
            matchesData,
            masteryData,
            url,
            currentTab,
            isLoaded,
        } = this.state;

        const activeStyle = {
            borderBottom: `solid ${this.props.theme.color.primary}`,
            color: this.props.theme.color.primary,
        };

        return (
            <StyledSummoner>
                {summonerData.summonerId ? (
                    <div className="summoner-content-header">
                        <div className="summoner-icon">
                            <img
                                className="summoner-icon-image"
                                src={require(`../assets/images/profileicon/${
                                    summonerData.profileIconId <= 4628
                                        ? summonerData.profileIconId
                                        : 0
                                }.png`)}
                                alt=""
                            />
                            <p className="summoner-level">
                                {summonerData.summonerLevel}
                            </p>
                        </div>
                        <div className="summoner-details">
                            <h2 className="summoner-name">
                                {summonerData.name}
                            </h2>
                            <Button
                                className="summoner-update-button"
                                color="primary"
                                onClick={this.handleUpdate}
                            >
                                Update
                            </Button>
                            <p className="summoner-last-updated">
                                Last Updated: {lastUpdate}
                            </p>
                        </div>
                    </div>
                ) : (
                    <p className="no-profile-text">Profile Doesn't Exist</p>
                )}

                <div className="summoner-content-container">
                    <nav>
                        <NavLink
                            className="nav-link"
                            activeStyle={activeStyle}
                            to={`${url}/overview`}
                            onClick={() => this.handleSelect("overview")}
                        >
                            Overview
                        </NavLink>
                        <NavLink
                            className="nav-link"
                            activeStyle={activeStyle}
                            to={`${url}/mastery`}
                            onClick={() => this.handleSelect("mastery")}
                        >
                            Mastery
                        </NavLink>

                        {/**!Add in later */}
                        <NavLink
                            className="nav-link"
                            activeStyle={activeStyle}
                            to={`${url}/champion-stats`}
                            onClick={() => this.handleSelect("champion-stats")}
                        >
                            Champion Stats
                        </NavLink>
                        <NavLink
                            className="nav-link"
                            activeStyle={activeStyle}
                            to={`${url}/live-game`}
                            onClick={() => this.handleSelect("live-game")}
                        >
                            Live Game
                        </NavLink>
                    </nav>
                    {/**If current tab is mastery and mastery data is loaded/exists */}
                    {currentTab === "mastery" && (
                        <Mastery
                            // summonerId={summonerData.summonerId}
                            masteryData={masteryData}
                            region={region}
                        />
                    )}
                    {currentTab === "overview" && (
                        <Overview
                            // summonerId={summonerData.summonerId}
                            rankData={rankData}
                            matchesData={matchesData}
                            region={region}
                            isLoaded={isLoaded}
                        />
                    )}
                    {currentTab === "champion-stats" && <p>Coming Soon!</p>}
                    {currentTab === "live-game" && <p>Coming Soon!</p>}
                </div>
            </StyledSummoner>
        );
    }
}

export default withTheme(Summoner);
