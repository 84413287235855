import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
    width: 115px;
    font-size: 1.3rem;
    font-weight: 700;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    /* background-color: #00cce7; */
    /**If color exists, picks color otherwise goes to default color or transparent*/
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 3px;
    background-color: ${(props) =>
        props.theme.color[props.color]
            ? props.theme.color[props.color]
            : props.theme.color.default
            ? props.theme.color.default
            : "transparent"};
`;

//Default theme for Buttons that arent wrapped in the ThemeProvider
StyledButton.defaultProps = {
    theme: {
        color: "#e74100",
    },
};

const Button = ({ className, children, color, onClick }) => {
    return (
        <StyledButton className={className} color={color} onClick={onClick}>
            {children}
        </StyledButton>
    );
};

export default Button;
