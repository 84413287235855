import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import RegionSelector from "./RegionSelector";
import RegionOption from "./RegionOption";

const StyledSearchbar = styled.form`
    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
        outline: none;
    }

    .region-selector {
    }
    display: flex;
    height: 60%;
    .search-field-wrapper {
        position: relative;
        width: 100%;
        .search-field {
            height: 100%;
            width: 100%;
            border: none;
            font-size: 1.8rem;
            font-weight: 500;
            padding: 0 10px 0 4px;
        }
        .error-text {
            position: absolute;
            font-size: 1.4rem;
            color: red;
            top: 100%;
        }
    }
    .search-button {
        height: 100%;
        border: none;
        cursor: pointer;
        &:hover {
            color: ${(props) => props.theme.color.primary};
        }
        /* padding: 5px; */
        .fa-search {
            padding: 0 10px;
            font-size: 1.4rem;
        }
    }
`;

class Searchbar extends Component {
    constructor(props) {
        super(props);
        this.state = { value: "", region: "na1", errorText: "" };
        this.handleChange = this.handleChange.bind(this);
        // this.handleSearch = this.handleSearch.bind(this);
        this.handleRegionChange = this.handleRegionChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            value: event.target.value,
            errorText: "",
        });
    }
    handleRegionChange(region) {
        this.setState({
            region: region,
        });
    }
    // handleSearch(event) {
    //     if (this.state.value === "") {
    //         event.preventDefault();
    //         this.setState({
    //             errorText: "Search field cannot be blank",
    //         });
    //     } else {
    //         window.location.href = `/summoner/${
    //             this.state.region
    //         }/${this.state.value.trim().split(" ").join("+")}/overview`;
    //     }
    // }

    render() {
        const { backgroundColor, className } = this.props;
        const { value, region, errorText } = this.state;
        return (
            <StyledSearchbar
                className={className}
                onSubmit={this.handleSubmit}
                style={{ backgroundColor }}
            >
                <RegionSelector
                    className="region-selector"
                    value={region}
                    onChange={this.handleRegionChange}
                >
                    <RegionOption name="NA" value="na1">
                        North America
                    </RegionOption>
                    <RegionOption name="EUW" value="euw1">
                        Europe West
                    </RegionOption>
                    <RegionOption name="EUN" value="eun1">
                        Europe North & East
                    </RegionOption>
                    <RegionOption name="KR" value="kr">
                        Korea
                    </RegionOption>
                    <RegionOption name="BR" value="br1">
                        Brazil
                    </RegionOption>
                    <RegionOption name="JP" value="jp1">
                        Japan
                    </RegionOption>
                    <RegionOption name="RU" value="ru">
                        Russia
                    </RegionOption>
                    <RegionOption name="OCE" value="oc1">
                        Oceania
                    </RegionOption>
                    <RegionOption name="TR" value="tr1">
                        Turkey
                    </RegionOption>
                    <RegionOption name="LAN" value="la1">
                        Latin America North
                    </RegionOption>
                    <RegionOption name="LAS" value="la2">
                        Latin America South
                    </RegionOption>
                </RegionSelector>
                {/**!FIX: If you type something random like "transition: 1s ease-in;" the url will be messed up */}
                <div className="search-field-wrapper">
                    <input
                        className="search-field"
                        value={value}
                        onChange={this.handleChange}
                        style={{ backgroundColor }}
                        placeholder="Search Summoner"
                    />
                    <p className="error-text">{errorText}</p>
                </div>
                {/**!The to= doesn't matter, but will give off console error without it. */}
                <Link to={`/summoner/${region}/${value}/overview/`}>
                    <button
                        className="search-button"
                        // type="submit"
                        style={{ backgroundColor }}
                        // onClick={this.handleSearch}
                    >
                        <i className="fa fa-search" aria-hidden="true" />
                    </button>
                </Link>
            </StyledSearchbar>
        );
    }
}

export default Searchbar;
