import React, { Component } from "react";
import styled, { ThemeProvider } from "styled-components";
import { Home, Summoner } from "./pages/";
import NotFound from "./util/NotFound";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/layout/Navbar";
import theme from "../src/util/theme";

const StyledApp = styled.div`
    background: #292f35;
    /**This exists so when loading pages, there is no white in background */
    height: 100%;
    width: 100%;

    .text-shadow {
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    }
    .primary {
    }
`;
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        // const { value } = this.state;
        return (
            <ThemeProvider theme={theme}>
                <StyledApp>
                    <Router>
                        <Navbar />
                        <Switch>
                            {/* <Route
                            exact
                            path="/summoner/:summoner/mastery"
                            component={Mastery}
                        /> */}
                            <Route
                                exact
                                path="/summoner/:region/:summonerName/:stat"
                                component={Summoner}
                            />
                            <Route
                                exact
                                path="/summoner/:region/:summonerName/"
                                component={Summoner}
                            />
                            <Route exact path="/" component={Home} />
                            <Route component={NotFound} />
                        </Switch>
                    </Router>
                </StyledApp>
            </ThemeProvider>
        );
    }
}

export default App;
