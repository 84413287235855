export default {
    color: {
        primary: "#00cce7",
        secondary: "#e74100",
        default: "#e74100",
    },
    // text: {
    //     primary: "#fff",
    //     secondary: ""
    // },
    textPrimary: "#fff",
    textSecondary: "#000",
    font: {},
    fontSize: {},
};
