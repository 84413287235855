import React, { Fragment } from "react";
import styled from "styled-components";
import Card from "../../Card";
import ImageFetcher from "../../../util/ImageFetcher";

const StyledRankContent = styled.div`
    /* background: #292f35; */
    /* width: 100%; */
    .rank-content {
        display: grid;
        grid-template-columns: 1.2fr 2fr;
        align-items: center;
        .rank-detail {
            text-align: left;
            padding-right: 15px;
            .queue-name {
                font-size: 1.2rem;
            }
            .rank-text {
                font-size: 1.6rem;
                font-weight: 600;
            }
            .rank-stats {
                font-size: 1.3rem;
            }
        }
    }
    .solo {
        height: 130px;
        .rank-icon {
            width: 70px;
            margin: 0 auto;
        }
    }
    .flex {
        height: 115px;
        .rank-icon {
            width: 60px;
            margin: 0 auto;
        }
    }
    .primary-text {
        color: ${(props) => props.theme.color.primary};
    }
`;

function RankContent({
    rankData: { RANKED_SOLO_5x5, RANKED_FLEX_SR },
    className,
    isLoaded,
}) {
    // const rankData = {
    //     "RANKED_FLEX_SR": "Ranked Flex",
    // }

    const tierText = (queueType) => {
        // console.log(isLoaded);
        if (!isLoaded) return "Loading...";
        if (queueType) {
            return (
                queueType.tier[0] + queueType.tier.substring(1).toLowerCase()
            );
        } else {
            return "Unranked";
        }
    };

    const rankDetailMarkup = (queueType, queueName, queueClass) => {
        return (
            <Card className={`rank-content ${queueClass}`}>
                <ImageFetcher
                    className="rank-icon"
                    type="rank"
                    imageId={`Emblem_${tierText(queueType)}`}
                />
                <div className="rank-detail">
                    <p className="queue-name">{queueName}</p>
                    {queueType ? (
                        <Fragment>
                            <p className="rank-text">
                                <span className="primary-text">
                                    {tierText(queueType)}{" "}
                                    {rankText[queueType.rank]}
                                </span>
                                {" / "}
                                {queueType.leaguePoints} LP
                            </p>
                            <p className="rank-stats">
                                {queueType.wins}W {queueType.losses}L (
                                {Math.round(
                                    (queueType.wins /
                                        (queueType.wins + queueType.losses)) *
                                        100
                                )}
                                % WR)
                            </p>
                        </Fragment>
                    ) : (
                        <p className="rank-text">{tierText(queueType)}</p>
                    )}
                </div>
            </Card>
        );
    };
    const rankText = {
        I: 1,
        II: 2,
        III: 3,
        IV: 4,
    };

    return (
        <StyledRankContent className={className}>
            {rankDetailMarkup(RANKED_SOLO_5x5, "Ranked Solo", "solo")}
            {rankDetailMarkup(RANKED_FLEX_SR, "Ranked Flex", "flex")}
        </StyledRankContent>
    );
}

export default RankContent;
