import React, { Fragment } from "react";
import PropTypes from "prop-types";

//!fileExt exists for later in case not all image files are .png
function ImageFetcher({ className, alt, type, imageId, fileExt }) {
    const imgCheck = () => {
        const imageSrc = {
            //4200.png
            profileIcon: `/profileicon/${imageId}.png`,
            //Emblem_Bronze.png
            rank: `/ranked-emblems/${imageId}.png`,
            //Aatrox.png
            champion: `/champion/${imageId}.png`,
            mastery: `/champion-mastery-icons/${imageId}.png`,
            //SummonerBarrier.png
            spell: `/spell/${imageId}.png`,
            //1001.png
            item: `/item/${imageId}.png`,
        };
        try {
            if (type === "item" && imageId.toString() === "0") {
                return "empty slot";
            }
            return require(`../assets/images${imageSrc[type]}`);
        } catch (error) {
            //toString() to account for strings, and itemId: 0 means empty slot
            return undefined;
        }
    };

    return (
        <Fragment>
            {/* {console.log("imageCHECK", imgCheck() ? imgCheck() : "ERROR")} */}
            {imgCheck() && imgCheck() !== "empty slot" ? (
                <img className={className} src={imgCheck()} alt={alt} />
            ) : imgCheck() === "empty slot" ? (
                <div className={`${className} empty`} />
            ) : (
                <div
                    className={`${className} undefined`}
                    style={{ display: "grid", placeItems: "center" }}
                >
                    <p>?</p>
                </div>
            )}
        </Fragment>
    );
}

ImageFetcher.propTypes = {
    className: PropTypes.string,
    alt: PropTypes.string,
    type: PropTypes.string.isRequired,
    imageId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ImageFetcher;
