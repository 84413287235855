import React, { Component } from "react";
import styled from "styled-components";

const StyledRegionSelector = styled.div`
    .selector-button {
        position: relative;
        cursor: pointer;
        border: none;
        /* background: #fff; */
        display: flex;
        align-items: center;
        padding: 7px 10px 7px 14px;
        height: 100%;

        font-size: 1.2rem;
        font-weight: 500;
        .fa {
            margin-left: 7px;
        }
        &:hover {
            background-color: ${(props) => props.theme.color.primary};
            color: white;
            text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
        }
    }

    /*
    !Removed modal-backdrop for more versatile outside-click close.
     .option-modal-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    } */

    .option-list {
        position: absolute;
        list-style-type: none;
        background: #fff;
        border: 1px solid gray;
        width: 20em;
        .option-entry {
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 5px;
            transition: all 0.1s ease-in;
            /* font-weight: 500; */
            &:hover {
                color: ${(props) => props.theme.color.primary};
                font-weight: 700;
            }
            .option-item {
                padding: 5px;
                font-size: 1.4rem;
                font-weight: 500;
                text-align: center;
                width: 40px;
            }
            .option-item-fullname {
                padding: 5px;
                font-size: 1.3rem;
            }
        }
        .option-entry.selected {
            background-color: ${(props) => props.theme.color.primary};
            color: white;
        }
    }
`;
class RegionSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.children[0].props.name,
            optionModalIsOpen: false,
        };
        this.handleSelect = this.handleSelect.bind(this);
        this.handleOptionDialog = this.handleOptionDialog.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }
    componentDidMount() {
        window.addEventListener("click", this.handleClose);
    }
    componentWillUnmount() {
        window.removeEventListener("click", this.handleClose);
    }
    handleOptionDialog(event) {
        event.preventDefault();
        this.setState({
            optionModalIsOpen: !this.state.optionModalIsOpen,
        });
    }
    handleSelect(item, region) {
        this.setState({
            selected: item,
        });
        this.props.onChange(region);
    }
    handleClose(event) {
        if (!event.target.closest(".selector-button")) {
            this.setState({
                optionModalIsOpen: false,
            });
        }
    }

    render() {
        const { className, style, children } = this.props;
        const { selected, optionModalIsOpen } = this.state;
        return (
            <StyledRegionSelector className={className}>
                <div
                    className="selector-button"
                    onClick={this.handleOptionDialog}
                    style={style}
                >
                    {selected}{" "}
                    <i className="fa fa-angle-down" aria-hidden="true" />
                </div>

                {optionModalIsOpen && (
                    <div className="option-modal">
                        <ul className="option-list">
                            {children.map((child) => (
                                <li
                                    className={`option-entry ${
                                        selected === child.props.name &&
                                        "selected text-shadow"
                                    }`}
                                    key={child.props.value}
                                    onClick={() =>
                                        this.handleSelect(
                                            child.props.name,
                                            child.props.value
                                        )
                                    }
                                >
                                    <div className="option-item">
                                        {child.props.name}
                                    </div>
                                    <div className="option-item-fullname">
                                        {child.props.children}
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </StyledRegionSelector>
        );
    }
}

export default RegionSelector;
