import React from "react";
import styled from "styled-components";
import Card from "../../Card";

const StyledRankMostPlayed = styled(Card)`
    /* background: #292f35; */
`;

function RankMostPlayed({ region, rankData, className }) {
    return (
        <StyledRankMostPlayed className={className}>
            Loading...
        </StyledRankMostPlayed>
    );
}

export default RankMostPlayed;
