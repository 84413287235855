import React from "react";
import styled from "styled-components";
import Searchbar from "./Searchbar";
import { Link, withRouter } from "react-router-dom";

const StyledNavbar = styled.nav`
    position: fixed;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    height: 5.5em;
    top: 0;
    background: #262c30;
    /* border-bottom: 1px solid #000; */
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
    z-index: 99;
    .logo-nav {
        color: white;
        text-decoration: none;
        font-size: 3.4rem;
        padding-left: 40px;
        .logo-span {
            color: ${(props) => props.theme.color.primary};
        }
    }
    .searchbar-nav {
    }
`;

const Navbar = (props) => {
    return (
        <StyledNavbar>
            {/* {console.log("NAVBAR", props.history)} */}
            <Link to="" className="logo-nav">
                <span className="logo-span">GG</span>.ez
            </Link>
            <Searchbar className="searchbar-nav" backgroundColor="#fff" />
        </StyledNavbar>
    );
};

export default withRouter(Navbar);
