import React from "react";
import styled from "styled-components";

const StyledExpandedRowHeader = styled.ul`
    display: grid;
    align-items: center;
    color: #fff;
    list-style-type: none;
    padding: 7px 0;
    .bar-item {
        font-size: 1.4rem;
        font-weight: 600;
    }
`;

function MatchExpandedRowHeader({
    win,
    team,
    items,
    color,
    gridTemplateColumns,
}) {
    return (
        <StyledExpandedRowHeader style={{ gridTemplateColumns }}>
            <p>
                {win ? (
                    <span style={{ color: "#00a7ff", fontWeight: 600 }}>
                        Victory
                    </span>
                ) : (
                    <span style={{ color: "#ff3747", fontWeight: 600 }}>
                        Defeat
                    </span>
                )}
                {` (${team})`}
            </p>
            {items.map((item, index) => (
                <li key={index} className="bar-item">
                    {item}
                </li>
            ))}
        </StyledExpandedRowHeader>
    );
}

export default MatchExpandedRowHeader;
